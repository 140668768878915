import { Component, ViewEncapsulation, OnInit, OnDestroy } from '@angular/core';
import { Platform, AlertController } from '@ionic/angular';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { UpdatesAlertService } from './services/updates-alert.service';
import { StorageWrapperService } from './services/storage-wrapper.service';
import { UpdateService } from '../providers/servicer/update-service';
import { SwUpdate } from '@angular/service-worker';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: 'app.html',
  styleUrls: ['app.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MyApp implements OnInit, OnDestroy{
  private cacheUpdateSubscription: Subscription;

  constructor(
    private platform: Platform,
    private statusBar: StatusBar,
    private splashScreen: SplashScreen,
    private alertCtrl: AlertController,
    private updateService: UpdateService,
    private swUpdate: SwUpdate,
    private updateAlert: UpdatesAlertService,
    private storageWrapperService: StorageWrapperService,
  ) {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  ngOnInit() {
    this.updateAlert = new UpdatesAlertService(
      this.alertCtrl,
      this.swUpdate,
      this.updateService,
      this.storageWrapperService
    );

    document.addEventListener(
      'visibilitychange',
      async (ev) => {
        //アプリ再表示時に更新確認実行
        if (document.visibilityState === 'visible') {
          if (!this.updateAlert.isUpdating()) {
            this.updateAlert.checkForUpdateVersion();
          }
        }
      },
      false
    );

    //起動時に一度更新確認実行
    this.updateAlert.checkForUpdateVersion();
  }

  ngOnDestroy(): void {
    if (this.cacheUpdateSubscription) {
      this.cacheUpdateSubscription.unsubscribe();
      this.cacheUpdateSubscription = null;
    }
  }
}
