import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, Subscription, interval } from 'rxjs';
import { catchError, filter, flatMap, map, tap } from 'rxjs/operators';
import { of } from 'rxjs';

import { environment } from '../../environments/environment';
import { ApiResultType } from '../../providers/servicer/types/api-result-type';
import { UserV2ServiceProvider } from '../servicer/user-v2-service';
import { UserReservation } from '../servicer/models/user-reservation';
import { Const } from '../../providers/const';
import { UserReservationResponse } from '../servicer/models/user-reservation-response';
import { UserStatusType } from '../servicer/types/user-status-type';

/**
 * 予約カードを定期間隔で取得する専用プロバイダー.
 */
@Injectable()
export class ReservationsGetScheduleProvider {

  /** 予約カードを表示するユーザステータスのリスト. */
  readonly userStatusListDisplayReservationCard = [
    UserStatusType.GUIDANCE_RIDE_PLACE,
    UserStatusType.WAITING_USER,
    UserStatusType.WAITING_VEHICLE,
    UserStatusType.WAITING_AUTHENTICATION,
    UserStatusType.USER_AUTHENTICATION,
    UserStatusType.WAITING_RIDE,
    UserStatusType.RIDING
  ];

  private readonly reservationsEmptyResponse = {
    id: 0,
    loginId: '',
    name: '',
    iconUrl: '',
    rideCount: 0,
    detail: null
  } as UserReservationResponse;

  /** 予約一覧. */
  public reservations$: BehaviorSubject<UserReservationResponse>
    = new BehaviorSubject<UserReservationResponse>(this.reservationsEmptyResponse);

  /** インターバルのSubscription. */
  private intervalSubscription: Subscription = null;

  private userIdList: number[];

  private processing = false;

  constructor(
    public userV2Service: UserV2ServiceProvider
  ) {
  }

  /**
   * 開始.
   */
  start() {
    this.userIdList = [];
    const storageUserReservationInfo: UserReservation = JSON.parse(localStorage.getItem(Const.userReservationInfoStorageKey));

    if (storageUserReservationInfo) {
      Object.keys(storageUserReservationInfo).forEach((key) => {
        this.setUserIdList(+key);
      });
    }

    // 初回の予約一覧取得
    this.execute();

    //一定周期で「getReservations」を実行
    if (!this.intervalSubscription) {
      this.intervalSubscription = interval(environment.setting.reservationsGetInterval)
        .subscribe(() => this.execute());
    }
  }

  /**
   * 停止.
   */
  stop() {
    this.reservations$.next(this.reservationsEmptyResponse);

    if (this.intervalSubscription) {
      this.intervalSubscription.unsubscribe();
      this.intervalSubscription = null;
    }
  }

  /**
   * 予約カード情報取得します.
   */
  execute() {
    of(this.processing)
      //APIが実行中ではない場合
      .pipe(filter(() => !this.processing))
      //API実行中フラグを更新　true:実行中
      .pipe(tap(() => this.processing = true))
      //APIを実行中
      .pipe(flatMap(() => this.getReservations()))
      //API実行中フラグを更新　false:実行中ではない
      .pipe(tap(() => this.processing = false))
      .subscribe();
  }

  /**
   * 配列にuserIdを追加する(キュー)
   * @param userId ユーザーID
   */
  public setUserIdList(userId: number) {
    this.userIdList.push(userId);
  }

  /**
   * 配列からuserIdを削除する
   * @param deleteId ユーザーID
   */
  private clearUserId(deleteId: number) {
    const deleteIndex = 1;
    this.userIdList.splice(this.userIdList.indexOf(deleteId), deleteIndex);
  }

  /**
   * 予約一覧を取得します.
   * 定期的に呼び出されるため、エラーが発生しても、何も処理をしません.
   */
  private getReservations(): Observable<UserReservationResponse> {
    //ローカルストレージから予約情報を取得
    const storageUserReservationInfo: UserReservation = JSON.parse(localStorage.getItem(Const.userReservationInfoStorageKey));

    //予約情報が空の場合、この関数を終了する
    if (!storageUserReservationInfo) {
      return this.reservations$;
    }

    //response格納用
    let responseuserReservation: UserReservationResponse;

    //最初の要素のuserIdを取得
    const userId = this.userIdList[0];

    //取得したuserIdを配列から削除
    this.clearUserId(userId)

    //キーを渡して予約情報を取得
    const userInfo = storageUserReservationInfo[userId];

    if (userInfo) {
      return this.userV2Service
        .dispatchConfirmation({
          user_id: userId,
          lat: userInfo.lat,
          lon: userInfo.lon,
          accuracy: userInfo.accuracy,
          language: userInfo.language
        })
        .pipe(
          map((response) => {
            console.log('レスポンスuserID：' + userId + '　' + response.user_status);
            if (response.result === ApiResultType.SUCCESS) {
              responseuserReservation = {
                id: userId,
                loginId: userInfo.loginId,
                name: userInfo.name,
                iconUrl: userInfo.iconUrl,
                rideCount: userInfo.rideCount,
                detail: response
              }
  
              //true:追加する　false:追加しない
              const isAddId = this.userStatusListDisplayReservationCard.some((userStatus) => userStatus === response.user_status);
  
              //ユーザーステータスが1以外の場合、userIdを配列に格納
              if (isAddId) {
                this.setUserIdList(responseuserReservation.id);
              }
              this.reservations$.next(responseuserReservation);
            } else {
              // レスポンスがSUCCESS以外の場合、userIdを配列に再登録
              this.setUserIdList(userId);
            }
            return this.reservations$.value;
          }),
          catchError(() => {
            // 例外発生の場合、userIdを配列に再登録
            this.setUserIdList(userId);
            return of(this.reservations$.value);
          })
        )
        
        
    }
    return this.reservations$;
  }
}