import { environment } from "../../environments/environment";
import { UserV2ServiceProvider } from "../../providers/servicer/user-v2-service";
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { UserDispatchConfirmationV2Response } from "../../providers/servicer/models/user-dispatch-confirmation-v2-response";
import { UserStatusType } from "../../providers/servicer/types/user-status-type";
import { AlertController } from "@ionic/angular";
import { SavePageInfo } from "./savePageInfo";
import { SaveStationInfo } from "./saveStationInfo";

@Injectable()
export class CheckStatus {

  /** 予約前のユーザステータスのリスト. */
  readonly userStatusListBeforeReservation = [
    UserStatusType.ACCEPTING_DISPATCH_ORDER,
    UserStatusType.BOARDING_LOCATION_PLANNING_ORDER,
    UserStatusType.DISPATCH_PLANNING_ORDER,
    UserStatusType.DISPATCH_TENTATIVE_RESERVATION_ORDER,
    UserStatusType.DISPATCH_RESERVATION_CHECK_CONFIRMATION
  ];

  constructor(
    private userV2Service: UserV2ServiceProvider,
    private alertController: AlertController,
    private savePageInfo: SavePageInfo,
    private saveStationInfo: SaveStationInfo
      ) { }


  /**
   * userStatusを取得
   * @param loginId ユーザーID
   * @returns 　UserDispatchConfirmationV2Response
   */
  public runDispatchConfirmation(loginId: number): Observable<UserDispatchConfirmationV2Response> {
    return this.userV2Service.dispatchConfirmation({
      user_id: loginId,
      lat: environment.mapInitialStateLat,
      lon: environment.mapInitialStateLng,
      accuracy: 0,
      language: environment.setting.language
    })
  }

  public async presentAlert() {
    const alert = await this.alertController.create({
      header: '予約不可',
      subHeader: 'このユーザーは既に予約が入っております',
      cssClass: 'modal-alert',
      message:  '',
      buttons: [{
        text: 'ログイン画面に戻る',
        handler: ()=>{
          this.savePageInfo.clearPageInfo();
          this.saveStationInfo.clearStationInfo();
        } 
      }],
    });

    await alert.present();
  }

}
