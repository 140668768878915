import { environment } from '../../environments/environment';

export class MessageLoader {
  private messageload = '';

  async load()
  {
    await fetch(environment.addressMessageBoardUrl,{
      method:'GET'
    })
    .then(res => res.text())
    .then(data => {
      const array = data.split('\r\n').join(('<br>'));
      console.log("MessageBoard:" + array);
    this.messageload = array;

    });
  }

  getMessege() {
    return this.messageload;
  }
}
