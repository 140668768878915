import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ErrorHandler, NgModule } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { IonicModule } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { MyApp } from './app.component';

import { CustomLoadingComponentModule } from '../components/custom-loading/custom-loading.module';

import { LoadingProvider } from '../providers/loading/loading';
import { ReservationsGetScheduleProvider } from '../providers/reservations-get-schedule/reservations-get-schedule';
import { AppMonitorV2ServiceProvider } from '../providers/servicer/app-monitor-v2-service';
import { StationV2ServiceProvider } from '../providers/servicer/station-v2-service';
import { UserV2ServiceProvider } from '../providers/servicer/user-v2-service';
import { MessageLoader } from '../providers/servicer/message-loader';
import { UserVehicleV2ServiceProvider } from '../providers/servicer/user-vehicle-v2-service';
import { SavePageInfo } from './services/savePageInfo';
import { SaveStationInfo } from './services/saveStationInfo';
import { CsvLoader } from './services/csv-loader';
import { MapperUtil } from './utilities/mapper-util';
import { CheckStatus } from './services/checkStatus';
import { CheckIsTsushima } from './services/checkIsTsushima';
import { AppRoutingModule } from './app-routing.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { UpdatesAlertService } from './services/updates-alert.service';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
@NgModule({
  declarations: [
    MyApp
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot(),
    HttpClientModule,
    CustomLoadingComponentModule,
    AppRoutingModule,
    TranslateModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: createTranslateLoader,
          deps: [HttpClient]
      }
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.serviceWorkerRegister
      }
    )
  ],
  bootstrap: [MyApp],
  providers: [
    StatusBar,
    SplashScreen,
    AndroidPermissions,
    {provide: ErrorHandler, useClass: ErrorHandler},
    LoadingProvider,
    ReservationsGetScheduleProvider,
    AppMonitorV2ServiceProvider,
    StationV2ServiceProvider,
    UserV2ServiceProvider,
    MessageLoader,
    UserVehicleV2ServiceProvider,
    SavePageInfo,
    SaveStationInfo,
    CsvLoader,
    MapperUtil,
    CheckStatus,
    CheckIsTsushima,
    UpdatesAlertService
  ]
})
export class AppModule {}
