import { Const } from "../../providers/const";
import { Injectable } from '@angular/core';
import { InputCheckTsushimaStationInfo, OutputConectTsushimaStationInfo } from "../../models/tsushimaStationInfo";

@Injectable()
export class CheckIsTsushima {

  /**津島対応駅コード一覧 */
  private readonly tsushimaStationCodeList = ['NS1901', 'NS3001'];
  private stationInfo: OutputConectTsushimaStationInfo = {
    isTsushimaStation: false,
    name: ''
  };

  /**
   * 入力引数の駅が、津島関連の場合、駅名から文字列「（準備中）」を削除
   * 津島関連以外の駅の場合、そのまま駅名を代入
   * @param station 駅情報の詳細
   * @returns TsushimaStationInfo
   */
  public checkIsTsushimaStation(station: InputCheckTsushimaStationInfo): OutputConectTsushimaStationInfo {
    if (this.tsushimaStationCodeList.some(tsushimaCode => station.code === tsushimaCode)) {
      this.stationInfo = {
        isTsushimaStation: true,
        name: station.name.replace(Const.prepareStr, '')
      }
    } else {
      this.stationInfo = {
        isTsushimaStation: false,
        name: station.name
      }
    }
    return this.stationInfo;
  }
}
