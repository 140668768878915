import { PageInfo } from "../../models/paga-info";


export class SavePageInfo {

  private pageList = {
    pageIndex: 0,
    pageStatus: []
  } as PageInfo;

  private screenStatus: string;

 
  /**
   * anotherTopコンポネントのページ情報を保存
   * @param index ページインデックス
   * @param statusList べージステータスリスト
   */
  public setPageInfo(index: number, statusList: any) {
    this.pageList.pageIndex = index;
    this.pageList.pageStatus = statusList;
  }

  /**
   * anotherTopコンポネントのページ情報を取得
   * @returns ページステータスとインデックス
   */
  public getPageInfo() {
    return this.pageList;
  }

  /**
   * ページ情報をクリア
   */
  public clearPageInfo() {
    this.pageList.pageIndex = 0;
    this.pageList.pageStatus = null;
  }

  /**
   * home.htmlのコンポネントステータスを保存
   * @param updata コンポネントステータス
   */
  public setHomePreScreenStatus(updata: string) {
    this.screenStatus = updata;
  }

  /**
   * home.htmlのコンポネントステータスを取得
   * @returns 
   */
  public getHomePreScreenStatus() {
    return this.screenStatus;
  }

}
