import { UserLoginV2Response } from "../../providers/servicer/models/user-login-v2-response";

export class SaveStationInfo {
  //乗車地のコード
  private puStation: any;

  //降車地のコード
  private doStation: any;

  //乗車地を選ぶ、または降車地を選ぶFlag　true:乗車地を選ぶ　false:降車地を選ぶ
  private isSelectPuStation = true;

  //モーダル（乗車地を決定しました）の表示Flag
  private isDecisionPuSpot = false;

  private userInfo = <UserLoginV2Response>{
    result: 0,
    message: '',
    user_id: 0,
    user_name: '',
    login_id: '',
    user_icon_img_url: '',
    option: null
  }

  private readonly emptyUserInfo = <UserLoginV2Response>{
    result: 0,
    message: '',
    user_id: 0,
    user_name: '',
    login_id: '',
    user_icon_img_url: '',
    option: null
  }
  
  /** 乗車地情報保管 */
  public setPuStation(updated: any, spot?: string) {
    if(spot){
      updated.nickName = spot;
    }
    this.puStation = updated;
  }

  /** 乗車地情報取得 */
  public getPuStation() {
    return this.puStation;
  }

  /** 降車地情報保管 */
  public setDoStation(updated: any, spot?: string) {
    if(spot){
      updated.nickName = spot;
    }
    this.doStation = updated;
  }
  
  /** 降車地情報取得 */
  public getDoStation() {
    return this.doStation;
  }

  /** 乗車地を選ぶ、または降車地を選ぶFlag保管 */
  public setIsSelectPuStation(updated: boolean) {
    this.isSelectPuStation = updated;
  }
  /** 乗車地を選ぶ、または降車地を選ぶFlag取得 */
  public getIsSelectPuStation() {
    return this.isSelectPuStation;
  }

  /** 乗車地を決定しましたのモーダルFlag保管 */
  public setOpenModalFlag(updated: boolean) {
    this.isDecisionPuSpot = updated;
  }

  /** 乗車地を決定しましたのモーダルFlag取得 */
  public getOpenModalFlag() {
    return this.isDecisionPuSpot;
  }

  /** ユーザー情報保管 */
  public setUserInfo(updated: any) {
    this.userInfo = updated;
  }

  /** ユーザー情報取得 */
  public getUserInfo() {
    return this.userInfo;
  }

  /** 変数のクリア */
  public clearStationInfo() {
    //乗車地
    this.puStation = '';

    //降車地
    this.doStation = '';

    //乗車地か降車地なのかFlag　true:乗車地　false:降車地
    this.isSelectPuStation = true;

    //モーダル（乗車地を決定しました）の表示Flag
    this.isDecisionPuSpot = false;    
  }

  public clearUserInfo(){
    this.userInfo = this.emptyUserInfo;
  }

  /** 乗車地降車地のクリア（いろんな箇所で使用するため作成） */
  public clearPuDoStationInfo(){
    if(this.isSelectPuStation){
      this.puStation = '';
    }else{
      this.doStation = '';
    }
  }
}
