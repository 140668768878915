import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { StationForSearch } from './servicer/models/stationForSearch';

import { Const } from '../../providers/const';

@Injectable()
export class CsvLoader {

  readonly syllabary = [
    ['ア', 'イ', 'ウ', 'エ', 'オ'],
    ['カ', 'キ', 'ク', 'ケ', 'コ', 'ガ', 'ギ', 'グ', 'ゲ', 'ゴ'],
    ['サ', 'シ', 'ス', 'セ', 'ソ', 'ザ', 'ジ', 'ズ', 'ゼ', 'ゾ'],
    ['タ', 'チ', 'ツ', 'テ', 'ト', 'ダ', 'ヂ', 'ヅ', 'デ', 'ド'],
    ['ナ', 'ニ', 'ヌ', 'ネ', 'ノ'],
    ['ハ', 'ヒ', 'フ', 'ヘ', 'ホ', 'バ', 'ビ', 'ブ', 'ベ', 'ボ', 'パ', 'ピ', 'プ', 'ペ', 'ポ'],
    ['マ', 'ミ', 'ム', 'メ', 'モ'],
    ['ヤ', 'ユ', 'ヨ', '', ''],
    ['ラ', 'リ', 'ル', 'レ', 'ロ'],
    ['ワ', 'ヲ', 'ン', '', '']
  ];

  readonly CODE_ROW_COLUMN = 0;
  readonly TYPE_ROW_COLUMN = 1;
  readonly NAME_ROW_COLUMN = 2;
  readonly YOMI_ROW_COLUMN = 3;
  readonly DISTRICT_L_COLUMN = 4;
  readonly DISTRICT_S_COLUMN = 5;
  readonly LAT_ROW_COLUMN = 6;
  readonly LON_ROW_COLUMN = 7;
  readonly ADDRESS_ROW_COLUMN = 8;
  readonly NICKNAME_COLUMN = 10;
  readonly NICKNAME_KANA_COLUMN = 11;
  readonly KEYWORD_COLUMN = 12;
  readonly KEYWORD_KANA_COLUMN = 13;

  static readonly TYPE_MAIN = 1;
  static readonly TYPE_CENTRAL_KEYWORD = 2;
  static readonly TYPE_CENTRAL_NOT_KEYWORD = 3;
  static readonly TYPE_PERIPHERAL = 4;

  public stations = new Array<StationForSearch>();
  public stationsForSearch = new Array<StationForSearch>();
  public stationsForDoSearch = new Array<StationForSearch>();

  nicknameList = new Array<Nickname>();
  keywordList = new Array<Keyword>();

  constructor(
  ) {
    this.load();
  }

  async load() {
    this.stations = new Array<StationForSearch>();
    this.stationsForSearch = new Array<StationForSearch>();
    this.stationsForDoSearch = new Array<StationForSearch>();
    this.nicknameList = new Array<Nickname>();
    this.keywordList = new Array<Keyword>();

    await fetch(environment.addressCsvUrl, {
      method: 'GET'
    })
      .then(req => req.text())
      .then(csv => {
        const tmp = csv.split('\r\n');

        for (let index = 1; index < tmp.length; index++) {
          //カンマ区切りで配列に分割
          let row = tmp[index].split(',');
          const data: StationForSearch = {
            id: -1,
            code: row[this.CODE_ROW_COLUMN],
            type: Number.parseFloat(row[this.TYPE_ROW_COLUMN]),
            name: row[this.NAME_ROW_COLUMN],
            yomi: row[this.YOMI_ROW_COLUMN],
            address: row[this.ADDRESS_ROW_COLUMN],
            lat: Number.parseFloat(row[this.LAT_ROW_COLUMN]),
            lon: Number.parseFloat(row[this.LON_ROW_COLUMN]),
            districtL: row[this.DISTRICT_L_COLUMN],
            districtS: row[this.DISTRICT_S_COLUMN],
            iconUrl: '',
            imgUrl: '',
            imgDropOffUrl: '',
            nickname: this.parseNickname(row[this.NICKNAME_COLUMN]),
            nicknameKana: this.parseNickname(row[this.NICKNAME_KANA_COLUMN]),
            keyword: this.parseKeyword(row[this.KEYWORD_COLUMN]),
            keywordKana: this.parseKeyword(row[this.KEYWORD_KANA_COLUMN])
          };

          this.stations.push(data);

          for (let i = 0; i < data.nickname.length; i++) {
            const nickname: Nickname = {
              name: data.nickname[i],
              yomi: data.nicknameKana[i],
              station: data
            };

            this.nicknameList.push(nickname);

            if (!data.keyword) {
              continue;
            }

            for (let j = 0; j < data.keyword[i].length; j++) {
              let element = this.keywordList.find((keyword) => keyword.word === data.keyword[i][j]);
              if (element) {
                element.nickName.push(nickname);
              }
              else {
                let keyword: Keyword = {
                  word: data.keyword[i][j],
                  yomi: data.keywordKana[i][j],
                  nickName: new Array<Nickname>(nickname)
                };

                this.keywordList.push(keyword);
              }
            }
          }

          const dataType = Number.parseInt(data.type.toString());

          //typeが1,2,3,9ではない場合検索候補から外す
          if (dataType !== 1 && dataType !== 2 && dataType !== 3 && dataType !== 4 && dataType !== 9) {
            continue;
          }

          if (!Number.isInteger(data.type)) {
            data.name = data.name.replace(Const.prepareStr, '');
          }

          this.stationsForSearch.push(data);

          if (data.type !== 1 && data.type !== 2) {
            continue;
          }
          this.stationsForDoSearch.push(data);
        };

        this.keywordList.forEach(keyword => {
          keyword.nickName.sort((a, b) => a.yomi.localeCompare(b.yomi, 'ja'));
        });
      });
  }


  public getSpotFromInitial(word: string[]): Array<Nickname> {
    return this.nicknameList.filter(nickname => word.some((word) => word === nickname.yomi.charAt(0)));
  }

  public getKeywordFromInitial(word: string[]): Array<Keyword> {
    return this.keywordList.filter(keyword => word.some((word) => word === keyword.yomi.charAt(0)));
  }

  public getStation(code: string): StationForSearch {
    return this.stationsForSearch.find(spot => code === spot.code);
  }

  public import(csvInfo: csvData) {
    this.stations = csvInfo['stations'];
    this.stationsForSearch = csvInfo['stationsForSearch'];
    this.stationsForDoSearch = csvInfo['stationsForDoSearch'];
    this.nicknameList = csvInfo['nicknameList'];
    this.keywordList = csvInfo['keywordList'];
  }

  private parseNickname(nickNames: string): string[] {
    return nickNames.split('/');
  }

  private parseKeyword(keyword: string): string[][] {
    let keywords: string[][]
    if (!keyword) return keywords;
    let regex = /\{([^\[\}\s]+)/g;
    let point = keyword.match(regex)
      .map((s) => s.substring(1, s.length));
    if (!point) return keywords;

    keywords = new Array(point.length);
    for (let i = 0; i < point.length; i++) {
      keywords[i] = point[i].split('/');
    }
    return keywords;
  }
}

export interface Nickname {
  name: string;
  yomi: string;
  station: StationForSearch;
}

export interface Keyword {
  word: string;
  yomi: string;
  nickName: Nickname[];
}

export interface csvData {
  stations: StationForSearch[];
  stationsForSearch: StationForSearch[];
  stationsForDoSearch: StationForSearch[];
  nicknameList: Nickname[];
  KeywordList: Keyword[];
}