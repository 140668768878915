import { Station } from './../services/servicer/models/station';
import { UserDoStationCodesV2ApiResponse } from '../../providers/servicer/models/user-do-station-codes-v2-api-response';
import { DoStationCodes, StationGroup } from '../services/servicer/models/do-station-codes';
import { AnotherDestData } from '../../providers/servicer/models/anotherdestData';
import { StationForSearch } from '../../app/services/servicer/models/stationForSearch';
import L from "leaflet";

export class MapperUtil {

  static readonly mainStationList = ['NH0101', 'NH0103', 'NH0104', 'NH0105', 'NH0106', 'NH0113', 'NH0114'];
  /**
   * UserPuStationCodesV2ApiResponseデータをDoStationCodesに変換する.
   * @param data UserDoStationCodesV2ApiResponseデータ
   * @param stations ストップポイント一覧
   * @returns DoStationCodesデータ
   */
  static stationButtonCallback: (code: string) => void = (code) => { };


  static mapperUserPuStationCodesV2ApiResponseToDoStationCodes(data: UserDoStationCodesV2ApiResponse, stations: Station[]): DoStationCodes {
    const ret = {
      mainStations: [],
      stationGroups: []
    } as DoStationCodes;

    data.mainStationCodes.forEach((code) => {
      const station = stations.find((station) => station.code === code);
      if (station) {
        ret.mainStations.push(station);
      }
    });

    data.stationGroups.forEach((group) => {
      const stationGroup = {
        name: group.name,
        lat: group.lat,
        lon: group.lon,
        zoom: group.zoom,
        doStations: []
      } as StationGroup;

      group.doStationCodes.forEach((code) => {
        const station = stations.find((station) => station.code === code);
        if (station) {
          stationGroup.doStations.push(station);
        }
      });

      ret.stationGroups.push(stationGroup);

    });


    return ret;
  }

  static mapperInitializeData(destdata: AnotherDestData): void {
    if (typeof (destdata.stationIconMap) === 'undefined') {
      destdata.stationIconMap = new Map();
    }
    destdata.selectedStationIcon = null;
    destdata.searchType = "";
    destdata.selectedSpotName = "";
    destdata.drawingStationsState = {
      stations: [],
      pudo: ''
    }

  }

  static mapperIDestroyData(destdata: AnotherDestData): void {
    if (destdata.mapDisplayClass !== "load") {
      destdata.mapDisplayClass = "hide";
    }
  }


  static mapperSetStation(destdata: AnotherDestData, station: StationForSearch, _zoom: number, selected: boolean = true): void {
    destdata.myMap.alignCenter(station.lat, station.lon, { zoom: _zoom });
    this.mapperDrawStationIcon(destdata, [station], 'do', selected);
  }



  static mapperClearAllStationIcons(destdata: AnotherDestData): void {
    destdata.stationIconMap.forEach((stationIcon) => {
      destdata.myMap.clear(stationIcon);
    });

    destdata.stationIconMap.clear();
    destdata.selectedStationIcon = null;
  }

  static mapperClearView(destdata: AnotherDestData) {
    this.mapperClearAllStationIcons(destdata);
  }


  static _DrawStationIcon(destdata: AnotherDestData, station: Station, selected?: boolean) {
    let className = '';

    if (station.type == 9) {
      className = 'simple-icon-target disabled';
    }
    else if (station.type == 2 || station.type == 3 || station.type == 4) {
      className = 'simple-icon-target';
    }

    if (destdata.myMap.getZoom() < 17) {
      className += className === '' ? 'simple' : ' simple';
    }

    const isMainStationList = this.mainStationList.some((stationCode) => stationCode === station.code);

    if (isMainStationList) {
      destdata.drawingStationsState.stations.push(station);
      destdata.stationIconMap.set(
        station.code,
        destdata.myMap.stationMainIconBuilder(station.lat, station.lon, station.name, () => {
        }, '../assets/img/map/station/map-station-icon-' + station.code + '.png').draw());
    } else {
      destdata.stationIconMap.set(
        station.code,
        destdata.myMap.stationIconBuilder(station.lat, station.lon, station.name, () => {
        })
          .class(className)
          .draw());
    }

    if (selected) {
      this.mapperSelectStationIcon(destdata, station.code, destdata.drawingStationsState.pudo);
    }
  }


  static mapperDrawStationIcon(destdata: AnotherDestData, stations: any, pudo?: string, selected?: boolean) {
    destdata.drawingStationsState.pudo = pudo;
    let className = '';
    destdata.drawingStationsState.stations = stations;
    stations.forEach((station) => {

      if (station.type == 9) {
        className = 'simple-icon-target disabled';
      }
      else if (station.type == 2 || station.type == 3 || station.type == 4) {
        className = 'simple-icon-target';
      }
      
      if (destdata.myMap.getZoom() < 17) {
        className += className === '' ? 'simple' : ' simple';
      }

      const isMainStationList = this.mainStationList.filter((stationCode) => {
        stationCode === station.code
      }).length !== 0;

      if (isMainStationList) {
        destdata.drawingStationsState.stations.push(station);
        destdata.stationIconMap.set(
          station.code,
          destdata.myMap.stationMainIconBuilder(station.lat, station.lon, station.name, () => {
            this.stationButtonCallback(station.code);
          }, '../assets/img/map/station/map-station-icon-' + station.code + '.png').draw());
      } else {
        destdata.stationIconMap.set(
          station.code,
          destdata.myMap.stationIconBuilder(station.lat, station.lon, station.name, () => {
            this.stationButtonCallback(station.code);
          })
            .class(className)
            .draw());
      }

      if (selected) {
        this.mapperSelectStationIcon(destdata, station.code, destdata.drawingStationsState.pudo);
      }

    });
  }


  static mapperSelectStationIcon(destdata: AnotherDestData, stationCode: string, pudo: string) {
    if (destdata.selectedStationIcon != null) {

      L.DomUtil.removeClass(destdata.selectedStationIcon._icon, 'selected');
      L.DomUtil.removeClass(destdata.selectedStationIcon._icon, 'pink');
      L.DomUtil.removeClass(destdata.selectedStationIcon._icon, 'blue');

    }
    destdata.selectedStationIcon = destdata.stationIconMap.get(stationCode);
    L.DomUtil.addClass(destdata.selectedStationIcon._icon, 'selected');
    if (pudo === 'pu') {
      L.DomUtil.addClass(destdata.selectedStationIcon._icon, 'pink');
    }
    else if (pudo === 'do') {
      L.DomUtil.addClass(destdata.selectedStationIcon._icon, 'blue');
    }
  }


  static mapperGetStationForSearchFromCode(destdata: AnotherDestData, code: string): StationForSearch {
    return destdata.csvLoader.stationsForSearch.find(spot => spot.code === code);
  }

}